import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { signOut } from "../store/actions/authActions";
import { updateBotStatus } from "../store/actions/taskActions";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { Link } from "react-router-dom";

import ChatsCard from "./ChatsCard";
import BotsCard from "./BotsCard";
import { Mixpanel } from "../constants/mixpanel";

const Dashboard = ({ auth, updateBotStatus, communicators, tasks, plan }) => {
  const [filteredTasks, setFilteredTasks] = useState();
  const isVip = plan.status === "active";
  const isLoadingPlan = plan.plan === null;
  const shouldBlockFeatures = !auth.emailVerified;

  useEffect(() => {
    if (tasks) {
      const newTasks = tasks.filter((task) => {
        const isTaskVisible = Boolean(task.invisible) === false;
        return isVip ? true : isTaskVisible;
      });

      setFilteredTasks(newTasks);
    }
  }, [tasks, plan.status]);

  React.useEffect(() => {
    Mixpanel.identify(auth.uid);
    Mixpanel.track("Visitou dashboard");
    Mixpanel.people.set({
      $name: auth.displayName || "Sem nome",
      $email: auth.email || "Sem email",
    });
  }, []);

  return (
    <div className="dashboard-container">
      <h2 className="vertical-offset-2">Dashboard</h2>
      {shouldBlockFeatures ? (
        <div>
          <p
            style={{
              background: "red",
              color: "white",
              padding: "8px",
              borderRadius: "8px",
            }}
          >
            Verifique seu e-mail para começar a usar o Betbots.
          </p>
          <p
            style={{
              fontSize: "14px",
              marginTop: "-8px",
            }}
          >
            Não encontrou o e-mail? Confira sua caixa de spam ou lixo
            eletrônico.
          </p>
          <p
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "8px",
            }}
          >
            Já verificou seu e-mail?
            <a
              className="btn btn-outline btn-small"
              onClick={(event) => {
                event.preventDefault();
                window.location.reload();
              }}
            >
              Clique aqui
            </a>
          </p>
        </div>
      ) : null}
      <div className="row">
        <div className="col-lg-6 col-xs-12">
          {filteredTasks && (
            <BotsCard
              bots={filteredTasks}
              botsBlocked={shouldBlockFeatures}
              onUpdateBot={(bot) => {
                updateBotStatus(bot);
              }}
            />
          )}
        </div>
        {shouldBlockFeatures ? null : (
          <>
            <div className="col-lg-6 col-xs-12">
              <ChatsCard chats={communicators} id={auth.uid} />
            </div>
            {isVip || isLoadingPlan ? null : (
              <div className="col-lg-6 col-xs-12">
                <div className="dashboard-cta-container card card-filled">
                  <p>
                    Nossos VIPs têm acesso às melhores ferramentas do mercado de
                    apostas!
                  </p>
                  <Link to="/checkout">
                    <button className="btn btn-inverted btn-large">
                      Quero ser VIP Agora
                    </button>
                  </Link>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    plan: state.plan,
    auth: state.firebase.auth,
    communicators: state.firestore.ordered.new_communicators,
    tasks: state.firestore.ordered.tasks,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
    updateBotStatus: (bot) => dispatch(updateBotStatus(bot)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    if (props.auth.isEmpty) {
      return [];
    } else {
      return [
        {
          collection: "new_communicators",
          where: ["uid", "==", props.auth.uid],
        },
        {
          collection: "tasks",
          where: ["userId", "==", props.auth.uid],
        },
      ];
    }
  })
)(Dashboard);
