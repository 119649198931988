export const signUp = (email, password) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();

    firebase
      .createUser(
        {
          email,
          password,
        },
        { email }
      )
      .then(() => {
        dispatch({ type: "LOGIN_SUCCESS" });

        firebase
          .auth()
          .currentUser.sendEmailVerification({
            url:
              "https://app.betbots.com.br/?verifiedEmail=" +
              firebase.auth().currentUser.email,
          })
          .then(() => {})
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((err) => {
        dispatch({ type: "LOGIN_ERROR", err });
      });
  };
};

export const signIn = (email, password) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        dispatch({ type: "LOGIN_SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "LOGIN_ERROR", err });
      });
  };
};

export const signInWithGoogle = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().languageCode = "pt";

    firebase
      .auth()
      .signInWithRedirect(provider)
      .then((result) => {
        dispatch({ type: "LOGIN_SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "LOGIN_ERROR", err });
      });
  };
};

export const signOut = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: "SIGNOUT_SUCCESS" });
      });
  };
};

export const setSource = (uid, source) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      const db = getFirestore();

      db.collection("sources").doc(uid).set({ uid: uid, source: source });
    } catch (err) {}
  };
};

export const clearErrors = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "CLEAR_ERRORS" });
  };
};
