const initState = {
  authError: null,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN_ERROR":
      return {
        ...state,
        authError: "Email ou senha inválidos. Tente novamente.",
      };
    case "LOGIN_SUCCESS":
      return {
        authError: null,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        authError: null,
      };
    case "SIGNOUT_SUCCESS":
      return state;
    default:
      return state;
  }
};

export default authReducer;
