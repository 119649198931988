import React from "react";
import { connect } from "react-redux";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import Dashboard from "./components/Dashboard";
import BotsTypeSelect from "./components/BotsTypeSelect";
import BotCornerNew from "./components/BotCornerNew";
import BotOpenNew from "./components/BotOpenNew";
import BotVolleyOpenNew from "./components/BotVolleyNew";
import Navbar from "./components/Navbar";
import PricingTable from "./components/PricingTable";
import CheckoutContainer from "./components/CheckoutContainer";
import SuccessPayment from "./components/SuccessPayment";
import FailPayment from "./components/FailPayment";
import BotOverGoals from "./components/BotOverGoalsNew";
import BotUnderGoals from "./components/BotUnderGoalsNew";
import BotOpenBetFair from "./components/BotOpenBetFair";
import BotOpenFifa from "./components/BotFifaNew";
import BotCustom from "./components/BotCustom";
import "./App.css";
import "flexboxgrid/dist/flexboxgrid.min.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faComment,
  faRobot,
  faFlag,
  faPaperPlane,
  faLock,
  faSignOutAlt,
  faLockOpen,
  faFutbol,
  faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";
import BotMollyNew from "./components/BotMollyNew";

library.add(faComment);
library.add(faRobot);
library.add(faFlag);
library.add(faPaperPlane);
library.add(faLock);
library.add(faSignOutAlt);
library.add(faLockOpen);
library.add(faFutbol);
library.add(faMinusCircle);

const App = ({ auth }) => {
  const shouldBlockFeatures = !auth.emailVerified;

  React.useEffect(() => {
    if (
      window.location.protocol === "http:" &&
      window.location.hostname !== "localhost"
    ) {
      window.location.href = `https://${window.location.hostname}${window.location.pathname}${window.location.search}`;
    }
  }, []);

  return (
    <BrowserRouter>
      <Navbar />

      <div className="container">
        <Switch>
          <Route path="/signin" component={SignIn} />
          <Route path="/signup" component={SignUp} />
          <Route exact path="/" component={Dashboard} />
          <Route path="/dashboard" component={Dashboard} />
          {shouldBlockFeatures ? null : (
            <>
              <Route path="/new-bot-type" component={BotsTypeSelect} />
              <Route path="/bot/:id" component={BotCornerNew} />
              <Route path="/bot-open/:id" component={BotOpenNew} />
              <Route path="/new-corner-bot" component={BotCornerNew} />
              <Route path="/new-open-bot" component={BotOpenNew} />
              <Route path="/new-open-volley-bot" component={BotVolleyOpenNew} />
              <Route path="/open-volley-bot/:id" component={BotVolleyOpenNew} />
              <Route path="/new-over-goals-bot" component={BotOverGoals} />
              <Route path="/new-under-goals-bot" component={BotUnderGoals} />
              <Route path="/over-goals-bot/:id" component={BotOverGoals} />
              <Route path="/under-goals-bot/:id" component={BotUnderGoals} />
              <Route path="/new-open-betfair-bot" component={BotOpenBetFair} />
              <Route path="/open-betfair-bot/:id" component={BotOpenBetFair} />
              <Route path="/new-open-molly-bot/" component={BotMollyNew} />
              <Route path="/new-open-fifa-bot/" component={BotOpenFifa} />
              <Route path="/open-fifa-bot/:id" component={BotOpenFifa} />
              <Route path="/new-custom-bot/" component={BotCustom} />
              <Route path="/custom-bot/:id" component={BotCustom} />
              <Route path="/open-custom-bot/:id" component={BotCustom} />
              <Route path="/upgrade" component={PricingTable} />
              <Route path="/checkout" component={CheckoutContainer} />
              <Route path="/sucesso" component={SuccessPayment} />
              <Route path="/cancelado" component={FailPayment} />
            </>
          )}
        </Switch>
      </div>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  };
};

export default connect(mapStateToProps)(App);
