import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BotsCardBlankslate = () => {
  return (
    <div className="card">
      <div className="card-title">
        Bots
        <FontAwesomeIcon icon="robot" />
      </div>
      <div className="row vertical-offset-3 center-xs">
        <div className="col-xs-12">
          <p>Confirme seu e-mail para criar seu primeiro robô.</p>
        </div>
        <div
          className="col-xs-12 col-lg-6 vertical-offset-3"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <button className="btn btn-primary" disabled>
            Criar primeiro robô
          </button>
        </div>
      </div>
    </div>
  );
};

export default BotsCardBlankslate;
