import React from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import {
  signInWithGoogle,
  signIn,
  clearErrors,
} from "../store/actions/authActions";
import logo from "../assets/BetBots-purple.svg";
import googleLogo from "../assets/google-logo.png";

const SignIn = ({ signIn, signInWithGoogle, auth, authError, clearErrors }) => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    signIn(email, password);
  };

  if (auth.uid) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div className="container">
      <div className="row middle-xs center-xs">
        <div className="col-xs-12">
          <img src={logo} className="logo vertical-offset-5" alt="Logo" />
        </div>
        <div className="col-xs-12 col-lg-4 vertical-offset-4">
          {authError ? (
            <div style={{ color: "red", marginBottom: "16px" }}>
              {authError}
            </div>
          ) : null}
          <form
            style={{
              display: "flex",
              boxSizing: "border-box",
              flexDirection: "column",
              gap: "16px",
            }}
            onSubmit={handleSubmit}
          >
            <div
              style={{
                display: "flex",
                boxSizing: "border-box",
                flexDirection: "column",
              }}
            >
              <label htmlFor="email" style={{ alignSelf: "flex-start" }}>
                E-mail:
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ padding: "8px" }}
                required
              />
            </div>
            <div
              style={{
                display: "flex",
                boxSizing: "border-box",
                flexDirection: "column",
              }}
            >
              <label htmlFor="password" style={{ alignSelf: "flex-start" }}>
                Senha:
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{ padding: "8px" }}
                required
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary btn-large btn-icon"
            >
              Entrar
            </button>
            <span style={{ display: "block", marginTop: "-4px" }}>
              Não tem uma conta?{" "}
              <Link to="/signup" onClick={clearErrors}>
                Cadastre-se
              </Link>
            </span>
          </form>
          <span
            style={{
              marginTop: "16px",
              marginBottom: "16px",
              display: "block",
            }}
          >
            OU
          </span>
          <button
            onClick={signInWithGoogle}
            className="btn btn-primary btn-large btn-icon"
            style={{ padding: "12px 16px" }}
          >
            <img src={googleLogo} className="google-icon" />
            Conectar com Google
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signInWithGoogle: () => dispatch(signInWithGoogle()),
    signIn: (email, password) => dispatch(signIn(email, password)),
    clearErrors: () => dispatch(clearErrors()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
