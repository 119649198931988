import React from "react";
import BotsCardBlankslate from "./BotsCardBlankslate";
import BotsCardBlocked from "./BotsCardBlocked";
import BotsCardList from "./BotsCardList";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";

const Bots = ({ bots, onUpdateBot, botsBlocked }) => {
  let botsView;
  if (!bots) {
    botsView = (
      <div className="card">
        <ReactPlaceholder
          type="text"
          rows={4}
          ready={!!bots}
          howLoadingAnimation={true}
        >
          <div />
        </ReactPlaceholder>
      </div>
    );
  } else if (bots.length > 0) {
    botsView = <BotsCardList bots={bots} onUpdateBot={onUpdateBot} />;
  } else if (botsBlocked) {
    botsView = <BotsCardBlocked />;
  } else {
    botsView = <BotsCardBlankslate />;
  }
  return <div>{botsView}</div>;
};

export default Bots;
